import { Button, Divider, IconButton, Pressable, Slider, Stack, Text } from 'native-base';
import { useEffect, useState } from 'react';
import { PlusCircleIcon, MinusCircleIcon } from 'react-native-heroicons/solid';

import { FormSubmitButton } from '../../FormSubmitButton';
import { StopPlanSuccessModal } from './ChangePlanStatus/StopPlanSuccessModal';
import { Intervention } from './ChangePlanStatus/types';
import { RetentionDiscountModal } from './RetentionDiscountModal';
import { TopperChoiceModal } from './TopperChoiceModal';
import { useAnswerSurveyAndCancelPlan, useInterventionEligibility } from './hooks';

import { usePlanSizesForRecipes, useUpdatePetPlan } from '@/api';
import { BuildPlan, DiscountExclusiveType, PetGender } from '@/api/types';
import { LoadingSpinner, displayToast } from '@/components/Elements';
import { ENABLE_UPDATE_DOG_PROFILE } from '@/config';
import { useDogModal } from '@/hooks';
import { useAccount } from '@/hooks/useAuth';
import segment from '@/segment';
import { contactUs } from '@/utils';
import { sendErrorReport } from '@/utils/analytics';

export const AdjustPortionSize = ({ navigation, route }: any) => {
  const account = useAccount();
  const {
    petId: routePetId,
    successRedirect,
    type,
    primaryAnswer,
    secondaryAnswer,
    customerComment,
  } = route.params ?? {};

  const [deepLinkParams, setDeepLinkParams] = useState({ petId: routePetId, successRedirect });

  useEffect(() => {
    const requestedUrl = localStorage.getItem('requestedUrl');
    if (requestedUrl) {
      const requestedURL = new URL(requestedUrl);
      const newParams: { petId?: string; successRedirect?: string } = {};

      if (!deepLinkParams.petId && requestedURL.searchParams.has('petId')) {
        const urlPetId = requestedURL.searchParams.get('petId');
        if (urlPetId) {
          newParams.petId = urlPetId;
          navigation.setParams({ petId: urlPetId });
        }
      }
      if (!deepLinkParams.successRedirect && requestedURL.searchParams.has('successRedirect')) {
        const urlSuccessRedirect = requestedURL.searchParams.get('successRedirect');
        if (urlSuccessRedirect) {
          newParams.successRedirect = urlSuccessRedirect;
          navigation.setParams({ successRedirect: urlSuccessRedirect });
        }
      }

      // We should only reset params if we have new params to set
      if (Object.keys(newParams).length > 0) {
        setDeepLinkParams((prev) => ({ ...prev, ...newParams }));
      }
    }
  }, [deepLinkParams.petId, deepLinkParams.successRedirect, navigation]);

  // petId is grabbed from either route or deep linking
  const petId = deepLinkParams.petId;
  const { pets } = account;
  const pet = pets.find((pet) => pet.id === petId);
  const currentIsTopper = pet?.pet_plan.is_topper || false;
  const isCancellationIntervention = type === 'CANCELLATION';
  const { interventions } = useInterventionEligibility(pet?.id);

  const [selectedPlanSizeIndex, setSelectedPlanSizeIndex] = useState(0);
  const [showTopperChoiceModal, setShowTopperChoiceModal] = useState(false);
  const [retentionDiscountModalOpen, setRetentionDiscountModalOpen] = useState(false);
  const [stopPlanSuccessModalOpen, setStopPlanSuccessModalOpen] = useState(false);

  const { isLoading: isSubmittingCancel, answerSurveyAndCancelPlan } =
    useAnswerSurveyAndCancelPlan();

  const { mutateAsync: updatePetPlan, isLoading: isLoadingUpdatePetPlan } = useUpdatePetPlan();
  const {
    Modal: UpdateDogModal,
    showModal: showUpdateDogModal,
    setShowModal: setShowUpdateDogModal,
  } = useDogModal('update');
  const pronoun = pet?.gender === PetGender.MALE ? 'his' : 'her';

  const { data: planSizesData, isSuccess: isSuccessPlanSizes } = usePlanSizesForRecipes({
    recipes: pet?.pet_plan.recipe_data.map((recipe) => recipe.id) || [],
    account_id: account.id,
  });
  const planSizes: BuildPlan[] = planSizesData ? Object.values(planSizesData) : [];
  const selectedPetPlan = planSizes[selectedPlanSizeIndex];

  useEffect(() => {
    navigation.setOptions({
      title: isCancellationIntervention ? 'Did you know?' : `Adjust ${pet?.name}'s Portion Size`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, pet]);

  useEffect(() => {
    if (pet && isSuccessPlanSizes) {
      setSelectedPlanSizeIndex(
        planSizes.findIndex(({ plan_size }) => plan_size === pet.pet_plan.plan_size)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pet, isSuccessPlanSizes]);

  const onPressChangePlanSize = (n: 1 | -1) => {
    if (selectedPlanSizeIndex + n >= 0 && selectedPlanSizeIndex + n < planSizes.length) {
      setSelectedPlanSizeIndex(selectedPlanSizeIndex + n);
    }
  };

  if (!(pet && selectedPetPlan)) {
    return <LoadingSpinner accessLabel="Adjust Portion Size Page" />;
  }

  const onSubmit = () => {
    if (isCancellationIntervention) {
      onConfirmPortion();
    } else if (selectedPetPlan.plan_size < pet.pet_plan.plan_size && !currentIsTopper) {
      setShowTopperChoiceModal(true);
    } else if (selectedPetPlan.plan_size > pet.pet_plan.plan_size && currentIsTopper) {
      setShowTopperChoiceModal(true);
    } else {
      onConfirmPortion();
    }
  };

  const onConfirmIntentToTopper = (intendsToKeepToperChoice: boolean) => {
    setShowTopperChoiceModal(false);
    onConfirmPortion(intendsToKeepToperChoice);
  };

  const onConfirmPortion = async (intentToTopper?: boolean) => {
    try {
      await updatePetPlan({
        petPlanId: pet.pet_plan.id,
        data: {
          plan_size: planSizes[selectedPlanSizeIndex].plan_size,
          is_topper: intentToTopper,
        },
      });
      if (isCancellationIntervention) {
        segment.trackEvent('Cancellation Flow - Adjust Daily Portion Size', {
          account_id: account.id,
          email: account.email,
          cancellation_reason: primaryAnswer,
          cancellation_reason_secondary: secondaryAnswer,
          cancellation_reason_comment: customerComment,
          pet_id: pet.id,
          previous_plan_size: pet.pet_plan.plan_size,
          new_plan_size: selectedPetPlan?.calories_per_day,
        });
      }
      if (successRedirect) {
        navigation.navigate(successRedirect || {});
      } else if (isCancellationIntervention) {
        navigation.navigate('Orders', { petId: pet.id });
      } else {
        navigation.navigate('Account');
      }
      displayToast({
        message: `${pet?.name}'s meal plan has been updated.`,
      });
    } catch (error) {
      sendErrorReport(error);
    }
  };

  const ResponsiveIcon = ({ icon }: { icon: 'minus' | 'plus' }) => {
    return (
      <Stack>
        <IconButton
          p="0px"
          display={{ base: 'flex', md: 'none' }}
          onPress={() => onPressChangePlanSize(icon === 'minus' ? -1 : 1)}
          icon={
            icon === 'minus' ? (
              <MinusCircleIcon color="blueBayoux.primary" size="20px" />
            ) : (
              <PlusCircleIcon color="blueBayoux.primary" size="20px" />
            )
          }
        />
        <IconButton
          p="0px"
          display={{ base: 'none', md: 'flex' }}
          onPress={() => onPressChangePlanSize(icon === 'minus' ? -1 : 1)}
          icon={
            icon === 'minus' ? (
              <MinusCircleIcon color="blueBayoux.primary" size="30px" />
            ) : (
              <PlusCircleIcon color="blueBayoux.primary" size="30px" />
            )
          }
        />
      </Stack>
    );
  };

  const onPressCancel = async () => {
    if (interventions.includes(Intervention.TenPercentDiscount)) {
      setRetentionDiscountModalOpen(true);
    } else {
      doCancelPlan();
    }
  };

  const navigateToAccount = () => {
    setStopPlanSuccessModalOpen(false);
    setRetentionDiscountModalOpen(false);
    navigation.navigate('Account');
  };

  const doCancelPlan = async () => {
    if (!pet) {
      return;
    }
    await answerSurveyAndCancelPlan({
      pet,
      primaryAnswer: route?.params?.primaryAnswer,
      secondaryAnswer: route?.params?.secondaryAnswer,
      customerComment: route?.params?.customerComment,
    });

    setRetentionDiscountModalOpen(false);
    setStopPlanSuccessModalOpen(true);
  };

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="center"
        h="100%"
        w="100%"
      >
        <Stack
          space={{ base: '16px', lg: '24px' }}
          px={{ base: '16px', md: '0px' }}
          w={{ base: '100%', md: '540px' }}
        >
          <Text
            color="black"
            fontWeight="medium"
            size="bodySmToMd"
            textAlign="center"
            mb={{ base: '8px', md: '16px' }}
          >
            {isCancellationIntervention
              ? 'Our recipes meet or exceed the standards set by AAFCO’s Dog Food Nutrient Profiles for all life stages.'
              : 'If you’d like to feed more or less food, you can adjust daily portion size below.'}
          </Text>
          <Stack variant="card" alignItems="center" space={{ base: '16px', md: '24px' }} mb="16px">
            <Stack>
              <Text
                size="bodyMdToLg"
                textAlign="center"
                fontWeight="bold"
                mb={{ base: '8px', md: '16px' }}
              >
                {isCancellationIntervention
                  ? `Adjust ${pet.name}'s Daily Portions`
                  : `${pet.name}'s Daily Portions`}
              </Text>
              <Text
                variant="helperText"
                size="bodySmToMd"
                color="sntGrey.tertiary"
                fontWeight="medium"
                textAlign="center"
              >
                Currently Feeding <Text fontWeight="bold">{pet.pet_plan.plan_size} calories</Text>
              </Text>
              <Text
                variant="helperText"
                size="bodySmToMd"
                color="sntGrey.tertiary"
                fontWeight="medium"
                textAlign="center"
              >
                {pet.pet_plan.is_topper ? 'Mixing S&T with other food' : 'Feeding 100% S&T'}
              </Text>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" space={{ base: '16px', md: '24px' }}>
              <ResponsiveIcon icon="minus" />
              <Stack
                direction="row"
                space={{ base: '4px', md: '8px' }}
                alignItems="center"
                justifyContent="cneter"
              >
                <Text
                  fontSize={{ base: 'title.sm', md: 'largeTitle' }}
                  lineHeight={{ base: 'title.sm', md: 'largeTitle' }}
                  fontWeight="bold"
                >
                  {selectedPetPlan?.calories_per_day}
                </Text>
                <Text fontWeight="medium" fontFamily="primary" size="bodyMlToTitleSm">
                  calories
                </Text>
              </Stack>
              <ResponsiveIcon icon="plus" />
            </Stack>
            <Slider
              value={selectedPlanSizeIndex}
              minValue={0}
              maxValue={planSizes?.length - 1}
              onChange={(value) => setSelectedPlanSizeIndex(value)}
              accessibilityLabel="calories"
            >
              <Slider.Track>
                <Slider.FilledTrack />
              </Slider.Track>
              <Slider.Thumb />
            </Slider>
            <Text fontWeight="medium" size="bodySmToMd">
              ${selectedPetPlan?.subscription.price.price_per_week}/wk
            </Text>
          </Stack>
          {isCancellationIntervention ? null : (
            <Text size="bodySmToMd" color="grey.dark" pb="32px" fontWeight="500">
              Has anything about {pet.name} changed?{' '}
              <Pressable
                onPress={() => {
                  if (ENABLE_UPDATE_DOG_PROFILE === 'true') {
                    navigation.navigate('UpdateDogProfile', {
                      successRedirect,
                      petId: pet.id,
                    });
                    return;
                  }
                  setShowUpdateDogModal(true);
                }}
              >
                <Text fontWeight="semibold" textDecorationLine="underline">
                  Update {pronoun} profile
                </Text>
              </Pressable>{' '}
              to see if we'd recommend changing {pronoun} portions.
            </Text>
          )}
        </Stack>
        <Stack variant="stickyFooter" width="100%" px="16px" pb="16px">
          {isCancellationIntervention ? (
            <Text fontWeight="medium" size="bodySmToMd" color="sntGrey.primary">
              Need help determining your dog's perfect portions? Call or text us at (718) 614-6292
            </Text>
          ) : (
            <Text fontWeight="medium" size="bodySmToMd" color="sntGrey.primary">
              Need help determining your dog's perfect portions?{' '}
              <Pressable
                onPress={contactUs}
                textDecorationLine="underline"
                fontWeight={700}
                color="black"
                size={{ base: 'bodySm' }}
              >
                <Text size="bodySmToMd">Contact us</Text>
              </Pressable>{' '}
              for personal advice — we'll make sure their daily portions are just right!
            </Text>
          )}
          <FormSubmitButton
            onPress={onSubmit}
            isDisabled={
              isLoadingUpdatePetPlan || selectedPetPlan.plan_size === pet.pet_plan.plan_size
            }
            mt="20px"
            mb="20px"
          >
            Continue
          </FormSubmitButton>
          {isCancellationIntervention ? (
            <Button variant="underlineMini" onPress={onPressCancel} isLoading={isSubmittingCancel}>
              Continue to cancellation
            </Button>
          ) : null}
        </Stack>
        {showUpdateDogModal ? <UpdateDogModal /> : null}
      </Stack>
      {showTopperChoiceModal && !isCancellationIntervention ? (
        <TopperChoiceModal
          isOpen={showTopperChoiceModal}
          onClose={() => setShowTopperChoiceModal(false)}
          decreaseOrIncrease={
            selectedPetPlan.plan_size < pet.pet_plan.plan_size ? 'decrease' : 'increase'
          }
          petName={pet.name}
          onConfirm={onConfirmIntentToTopper}
        />
      ) : null}
      <RetentionDiscountModal
        isOpen={retentionDiscountModalOpen}
        onClose={() => setRetentionDiscountModalOpen(false)}
        petId={pet.id}
        onSecondaryPress={doCancelPlan}
        discountType={DiscountExclusiveType.WINBACK_10_PERCENT}
        cancellationReason={route?.params?.primaryAnswer}
        cancellationReasonSecondary={route?.params?.secondaryAnswer}
        cancellationReasonComment={route?.params?.customerComment}
      />
      <StopPlanSuccessModal
        isOpen={stopPlanSuccessModalOpen}
        onPressX={navigateToAccount}
        onConfirm={navigateToAccount}
      />
    </>
  );
};
