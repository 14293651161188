import { NavigationAction, useIsFocused } from '@react-navigation/native';
import { Stack } from 'native-base';
import { useEffect, useMemo, useState } from 'react';

import { FormSubmitButton } from '../../FormSubmitButton';
import { ConfirmationModalForBackButton } from '../../Orders/RescheduleDelivery/ConfirmationModalForBackButton';
import { ConfirmPortionsModal, ReviewPlanSizeModal, TopperOrFullModal } from './Modals';
import { UpdateDogForm, useUpdateDogForm } from './UpdateDogForm';
import { UpdateDogFormValues } from './UpdateDogForm/types';

import {
  BodyTypeCode,
  getPlans,
  LifestyleCode,
  PetPlan,
  PetStatus,
  ProductType,
  useCallBuildPlansRegularPlan,
  useUpdatePet,
  useUpdatePetPlan,
} from '@/api';
import { displayToast, ToastType } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import { sortOrderByScheduled } from '@/utils';

export const UpdateDogProfile = ({
  navigation,
  route,
}: ProtectedScreenProps<'UpdateDogProfile'>) => {
  const [showConfirmationModalForBackButton, setShowConfirmationModalForBackButton] =
    useState(false);
  const [navigateAwayAction, setNavigateAwayAction] = useState<NavigationAction>();
  const [showReviewPlanModal, setShowReviewPlanModal] = useState(false);
  const [showTopperOrFullModal, setShowTopperOrFullModal] = useState(false);
  const [showConfirmPortionsModal, setShowConfirmPortionsModal] = useState(false);
  const [showDoubleCheckPortionsModal, setShowDoubleCheckPortionsModal] = useState<
    boolean | undefined
  >();

  const [petUpdateComplete, setPetUpdateComplete] = useState(false);
  const [petPlanUpdateComplete, setPetPlanUpdateComplete] = useState(false);

  const isFocused = useIsFocused();
  const account = useAccount();

  const { mutateAsync: callBuildPlansRegularPlan, data: newPlanData } =
    useCallBuildPlansRegularPlan();

  const { mutateAsync: updatePet, isLoading: isLoadingUpdatePet } = useUpdatePet();
  const { mutateAsync: updatePetPlan, isLoading: isLoadingUpdatePetPlan } = useUpdatePetPlan();

  const pet = account.pets.find((pet) => pet.id === route.params?.petId);

  const petNextPreviewOrderDate = useMemo(() => {
    if (route.params?.successRedirect === 'ReactivatePlan') return;

    const splitDate = account.orders.upcoming
      .filter((order) => order.products.some((product) => product.pet_plan?.pet_id === pet?.id))
      .sort(sortOrderByScheduled)
      .at(0)
      ?.scheduled.split('-');
    if (!splitDate) return;

    const year = Number(splitDate[0]);
    const monthIndex = Number(splitDate[1]) - 1;
    const day = Number(splitDate[2]);

    return new Date(year, monthIndex, day).toLocaleDateString('default', {
      month: 'short',
      day: 'numeric',
    });
  }, [account.orders.upcoming, pet?.id, route.params?.successRedirect]);

  const increasingOrDecreasing = useMemo(() => {
    if (!pet || !newPlanData) return;
    return pet.pet_plan.plan_size > newPlanData.plan_size ? 'decreasing' : 'increasing';
  }, [newPlanData, pet]);

  const defaultValues = {
    name: pet?.name,
    neutered: pet?.properties.neutered.toString(),
    breeds: pet?.breeds ?? [],
    birth_month: pet?.birth_month?.toString(),
    birth_year: pet?.birth_year.toString(),
    weight: pet?.properties.weight.toString(),
    body_type: pet?.properties.body_type as BodyTypeCode,
    lifestyle: pet?.properties.lifestyle as LifestyleCode,
  };

  const { formState, control, trigger, watch, reset, setError, clearErrors, handleSubmit } =
    useUpdateDogForm({
      defaultValues,
    });

  const formValues = watch();

  const unavailablePetNames = useMemo(
    () =>
      account.pets
        .filter(
          (pet) =>
            ![PetStatus.DECEASED, PetStatus.DECEASED_TO_NOT_LIST].includes(pet.status) &&
            pet.id !== route.params?.petId
        )
        .map((pet) => pet.name.toLocaleLowerCase().trim()),
    [account.pets, route.params?.petId]
  );

  const onConfirmationDontSave = () => {
    if (!navigateAwayAction) {
      throw new Error("User clicked Don't Save, but navigation action is missing");
    }
    navigation.dispatch(navigateAwayAction);
  };

  useEffect(() => {
    if (
      petUpdateComplete &&
      !showReviewPlanModal &&
      !showTopperOrFullModal &&
      !showConfirmPortionsModal
    ) {
      navigation.navigate(
        route.params?.successRedirect,
        route.params?.successRedirect === 'Dogs'
          ? { petId: pet?.id, scrollToTop: true, showDoubleCheckPortionsModal }
          : route.params?.successRedirectParams
      );
      displayToast({
        message: `${pet?.name}'s profile ${
          petPlanUpdateComplete ? 'and meal plan have' : 'has'
        } been updated.`,
        type: ToastType.Success,
      });
    }
  }, [
    navigation,
    pet?.id,
    pet?.name,
    petPlanUpdateComplete,
    petUpdateComplete,
    route.params?.successRedirect,
    route.params?.successRedirectParams,
    showConfirmPortionsModal,
    showDoubleCheckPortionsModal,
    showReviewPlanModal,
    showTopperOrFullModal,
  ]);

  useEffect(() => {
    navigation.setOptions({ title: `Update ${pet?.name}'s Profile` });
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, pet?.name]);

  useEffect(() => {
    const currentErrorType = formState.errors.name?.type;
    const currentName = watch('name');

    if (unavailablePetNames.includes(currentName.toLocaleLowerCase().trim())) {
      if (currentErrorType !== 'manual') {
        setError('name', {
          type: 'manual',
          message: 'You already have an existing pet with this name. Please use another name.',
        });
      }
    } else if (currentErrorType === 'manual') {
      clearErrors('name');
    }
  }, [setError, clearErrors, unavailablePetNames, formState.errors.name?.type, watch]);

  useEffect(() => {
    const handleBeforeRemove = (e: any) => {
      if (formState.isDirty && !petUpdateComplete && isFocused) {
        setNavigateAwayAction(e.data.action);
        setShowConfirmationModalForBackButton(true);
        e.preventDefault();
      }
    };
    const unsubscribe = navigation.addListener('beforeRemove', handleBeforeRemove);
    return unsubscribe;
  }, [formState, isFocused, navigation, petUpdateComplete]);

  if (!pet) {
    return null;
  }

  const updatePetOnly = async () => {
    await updatePet({
      accountId: account.id,
      petId: pet.id,
      ...formValues,
      weight: Number(formValues.weight),
    });
    setPetUpdateComplete(true);
  };

  const updatePetAndPetPlan = async (petPlanData: Partial<PetPlan>) => {
    await updatePet({
      accountId: account.id,
      petId: pet.id,
      ...formValues,
      weight: Number(formValues.weight),
    });
    await updatePetPlan({
      petPlanId: pet.pet_plan.id,
      data: petPlanData,
    });
    setPetUpdateComplete(true);
    setPetPlanUpdateComplete(true);
  };

  const onSubmit = async (data: UpdateDogFormValues) => {
    setShowConfirmationModalForBackButton(false);
    try {
      const planOptionsData = await getPlans({
        pet: {
          ...data,
          is_neutered: data.neutered === 'true',
          birth_month: data.birth_month ?? undefined,
        },
        discounts: [],
        sessionId: undefined,
        accountId: account.id,
      });

      const newPlanSize = pet.pet_plan.is_topper
        ? planOptionsData.topper_plan_size.id
        : planOptionsData.plan_size.id;

      await callBuildPlansRegularPlan({
        discounts: [],
        plan_size: newPlanSize,
        recipes: pet?.pet_plan.recipes,
        account_id: account.id,
      });

      if (newPlanSize === pet?.pet_plan.plan_size) {
        await updatePetOnly();
        return;
      }
      if (newPlanSize > pet?.pet_plan.plan_size) {
        setShowReviewPlanModal(true);
        return;
      }
      if (newPlanSize < pet?.pet_plan.plan_size) {
        if (data.body_type === BodyTypeCode.ALittleChubby) {
          setShowReviewPlanModal(true);
          return;
        }
        setShowDoubleCheckPortionsModal(true);
        await updatePetOnly();
      }
    } catch (error) {
      // TODO: add error handling
      console.error('Error building plan', error);
    }
  };

  const onSaveTopperOrFull = async (isMixing?: boolean) => {
    if (!isMixing) {
      setShowConfirmPortionsModal(true);
    } else {
      await updatePetAndPetPlan({
        is_topper: true,
      });
    }
    setShowTopperOrFullModal(false);
  };

  return (
    <>
      <Stack
        alignItems="center"
        alignSelf="center"
        w={{ base: '100%', md: '540px' }}
        px={{ base: '16px', md: '0px' }}
        pb={{ base: '32px', md: '48px' }}
      >
        <Stack space={{ base: '16px', lg: '24px' }} w="100%">
          <UpdateDogForm
            petName={pet.name}
            control={control}
            formState={formState}
            trigger={trigger}
          />
        </Stack>
      </Stack>
      <Stack variant="stickyFooter" alignItems="center" px="16px">
        <FormSubmitButton
          onPress={handleSubmit(onSubmit)}
          isDisabled={
            isLoadingUpdatePet ||
            isLoadingUpdatePetPlan ||
            !formState.isDirty ||
            !formState.isValid ||
            formState.isSubmitting
          }
          isLoading={isLoadingUpdatePet || isLoadingUpdatePetPlan || formState.isSubmitting}
        >
          Save Changes
        </FormSubmitButton>
      </Stack>
      <ConfirmationModalForBackButton
        isOpen={showConfirmationModalForBackButton}
        handleSavePress={handleSubmit(onSubmit)}
        handleCancelPress={onConfirmationDontSave}
        onClose={() => setShowConfirmationModalForBackButton(false)}
      />
      <ReviewPlanSizeModal
        isOpen={showReviewPlanModal}
        petName={pet.name}
        petGender={pet.gender}
        isTopper={pet.pet_plan.is_topper}
        planSize={{
          recommended: newPlanData?.plan_size ?? 0,
          current: pet.pet_plan.plan_size,
        }}
        weeklyPrice={{
          recommended: newPlanData?.subscription.price.price_per_week ?? '',
          current:
            pet.pet_plan.products.find((product) => product.product_type === ProductType.MEAL)
              ?.price_per_week ?? '',
        }}
        increasingOrDecreasing={increasingOrDecreasing}
        petNextPreviewOrderDate={petNextPreviewOrderDate}
        onPrimaryPress={async () => {
          await updatePetAndPetPlan({
            plan_size: newPlanData?.plan_size,
          });
          setShowReviewPlanModal(false);
          reset(undefined, { keepValues: true, keepDefaultValues: true });
        }}
        onSecondaryPress={async () => {
          if (pet.pet_plan.is_topper || increasingOrDecreasing === 'decreasing') {
            await updatePetOnly();
          } else {
            setShowTopperOrFullModal(true);
          }
          setShowReviewPlanModal(false);
        }}
        onClose={() => {
          reset(undefined, { keepValues: true, keepDefaultValues: true });
          setShowReviewPlanModal(false);
        }}
      />
      <TopperOrFullModal
        isOpen={showTopperOrFullModal}
        petName={pet.name}
        petGender={pet.gender}
        onSave={onSaveTopperOrFull}
        onClose={() => {
          reset(undefined, { keepValues: true, keepDefaultValues: true });
          setShowTopperOrFullModal(false);
        }}
      />
      <ConfirmPortionsModal
        isOpen={showConfirmPortionsModal}
        petName={pet.name}
        petGender={pet.gender}
        onIncreasePortions={async () => {
          await updatePetAndPetPlan({
            plan_size: newPlanData?.plan_size,
          });
          setShowConfirmPortionsModal(false);
        }}
        onKeepCurrentPortions={async () => {
          await updatePetOnly();
          setShowConfirmPortionsModal(false);
        }}
        onClose={() => {
          reset(undefined, { keepValues: true, keepDefaultValues: true });
          setShowConfirmPortionsModal(false);
        }}
      />
    </>
  );
};
